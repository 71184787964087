<template>
  <div class="background">
    <img class="backgroundImg-Blur" />
    <div class="container">
      <img class="backgroundImg" />
      <div class="walletConnectPage" ref="walletConnectPage">
        <div class="info" ref="info">
          <div class="wallet" ref="wallet">
            <input class="address" readonly type="text" v-model="response" />
            <button class="goApp" :disabled="isDisabled" @click="goApp"></button>
            <button class="connect" @click="openPopup"></button>
          </div>
        </div>
        
      </div>
    </div>

    <transition name="modal" v-if="isShowPopup">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div>
						<form class="frm_auth frm_signin form">
							<BaseButton
								type="button"
								class="closebtn"
								@click="closePopup"
							>
							</BaseButton>

							<BaseButton
								type="button"
								class="connectbtn metamask-btn"
								@click="connectMetamask"
							>
								<img
									src="../assets/img/ic-metamask.svg"
									alt=""
									class="ic-image"
								/>
								Metamask
							</BaseButton>

							<BaseButton
								type="button"
								class="connectbtn walletconnect-btn"
								@click="connectWalletConnect"
							>
								<img
									src="../assets/img/ic-walletconnect.svg"
									alt=""
									class="ic-image"
								/>
								WalletConnect
							</BaseButton>
							<BaseButton
								type="button"
								class="connectbtn coinbase-btn"
								@click="connectCoinbase"
							>
								<img
									src="../assets/img/ic-coinbase.svg"
									alt=""
									class="ic-image"
								/>
								Coinbase Wallet
							</BaseButton>
							<BaseButton
								type="button"
								class="connectbtn fortmatic-btn"
								@click="connectFortmatic"
							>
								<img
									src="../assets/img/ic-fortmatic.svg"
									alt=""
									class="ic-image"
								/>
								Fortmatic
							</BaseButton>
							<BaseButton
								type="button"
								class="connectbtn bitski-btn"
								@click="connectBitski"
							>
								<img
									src="../assets/img/ic-bitski.png"
									alt=""
									class="ic-image"
								/>
								Bitski
							</BaseButton>
						</form>
					</div>
				</div>
			</div>
		</div>
	</transition>
  </div>
</template>

<script>

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Fortmatic from "fortmatic";
import { Bitski } from "bitski";
import WalletLink from 'walletlink'
import Web3 from "web3";
import AppConfig from '@/App.Config.js'
var gConfig = AppConfig()

export default {
	name: 'WalletConnect',
	components: {
   
  },
  props: {
		type: {
			type: String,
			default: 'metamask',
		}
	},
  computed: {
    isDisabled() {
      return !this.showBtn; 
    }
  },
	data() {
		return {
      message: 'Connect from the Dvision Network.',
			provider: undefined,
      signer: undefined,
      showBtn: false,
      msg: "",
      response: "",
      isShowPopup: false
		}
	},
	methods: {
    async loadApp() {
      console.log(navigator.userAgent);
      let type = this.type;
      if(type != null || type != undefined) {
        type = type.toLowerCase();
      }
      if (type == "metamask") {
        await this.metamask();
      } else if (type == "coinbase") {
        await this.coinbase();
      } else if (type == "walletconnect") {
        await this.walletConnect();
      } else if (type == "fortmatic") {
        await this.fortmatic();
      } else if(type == "bitski"){
        await this.bitski();
      } else {
        await this.displayResponse("Invalid URL");
      }
    },
    //#region Metamask
    async metamask() {
      // const MMSDK = window.ethereum;
      const ethereum = window.ethereum; // You can also access via window.ethereum
      ethereum.request({method: 'eth_requestAccounts'})
      .then((res) => {
        this.response = res[0];
        this.showButton();
      })
      .catch((e) => this.displayResponse(e));

      ethereum.on('accountsChanged', (accounts) => {
        this.response = accounts[0];
        if(this.response == undefined) {
          this.showBtn = false;
        } else {
          this.showButton();
        }
      });
    },

    //#endregion

    //#region CoinBase
    async coinbase() {
      const walletLink = new WalletLink({
        appName: 'Division Network',
        appLogoUrl: 'https://dvision.app/img/NV-logo.ae27f28f.svg',
        darkMode: false,
        supportedChainIds: [1, 5, 56, 97, 137, 80001],
      })
      const coinbaseProvider = walletLink.makeWeb3Provider();
      coinbaseProvider.enable()
      .then(async (accounts) => {
        this.response = accounts[0];
        this.showButton();
      })
      .catch(error => {
        this.displayResponse(error);
      })
    },
    //#endregion

    //#region WalletConnect
    async walletConnect() {
      // Create a connector
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
      });

      if (connector.connected) {
        connector.killSession();
      }

      connector.createSession();

      connector.on("connect", async (error, payload) => {
        if (error) {
          this.displayResponse(error);
          throw error;
        }

        const { accounts, chainId } = payload.params[0];

        this.response = accounts[0];
        this.showButton();
        connector.killSession();
      });
    },
    //#endregion

    //#region Fortmatic
    async fortmatic() {
      sessionStorage.clear();
      const fortmaticProvider = new Fortmatic(
        gConfig.isProd ? gConfig.FORTMATIC_API_KEY_PRODUCT : gConfig.FORTMATIC_API_KEY_DEV
    );
      let web3 = new Web3(fortmaticProvider.getProvider());
      fortmaticProvider.user.login().then(() => {
        web3.eth.getAccounts().then(async (accounts) => {
          if (accounts) {
            this.response = accounts[0];
            this.showButton();
          } else {
            this.displayResponse("Invalid Fortmatic");
          }
        }).catch(error => {
          this.displayResponse(error);
        });
      });
    },
    //#endregion

    //#region Bitski
    async bitski() {
      const bitski = new Bitski
      (gConfig.isProd
		? gConfig.BITSKI_CLIENT_ID_PRODUCT
		: gConfig.BITSKI_CLIENT_ID_DEV,`${window.location.origin}/callback.html`);
      
      const res = await bitski.signIn();
      if (res) {
        if (res.accounts) {
          this.response = res.accounts[0];
          this.showButton();
        } else {
          this.displayResponse("Invalid Bitski");
        }
      } else {
        this.displayResponse("Error Bitski");
      }
    },
    //#endregion

    async goApp() {
      if(!this.showBtn) {
        return;
      }
      if(this.response == undefined || this.response.length != 42) {
        return;
      }
      let type = this.type;
      if(type != null || type != undefined) {
        type = type.toLowerCase();
      }
      // PC //
      
      if(!this.isMobile()) {
        window.focus();
        await new Promise((resolve) => setTimeout(resolve, 500));
        const res = this.response;
        try {
          await navigator.clipboard.writeText(res);
         } catch(e) {
          console.error(e);
         }
          
      }
      ////////

      window.location.href = `dvisionnetwork://walletConnect?${this.response}`;
    },

    displayResponse(text) {
      this.msg = text;
    },

    showButton() {
      this.showBtn = true;
      this.msg = this.response;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    myEventHandler(e) {
      const width = document.body.offsetWidth;
      if(this.isMobile()) {
        let rate = width / 1080;
        const walletConnectPage = this.$refs.walletConnectPage;
        if(walletConnectPage != null && walletConnectPage.style) {
          walletConnectPage.style.width = `${width}px`;
        }
        const info = this.$refs.info;
        if(info != null && info.style) {
          info.style.transform = `translateX(-50%) scale(${rate}, ${rate})`;
        }
        const wallet = this.$refs.wallet;
        if(wallet != null && wallet.style) {
          // wallet.style.paddingTop = `${336 * rate}px`;
        }
      } else {
        const rate = width / 1920;
        const walletConnectPage = this.$refs.walletConnectPage;
        if(walletConnectPage != null && walletConnectPage.style) {
          walletConnectPage.style.width = `${width}px`;
        }

        const info = this.$refs.info;
        if(info != null && info.style) {
          info.style.transform = `translate(-50%, -50%) scale(${rate}, ${rate})`;
        }
      }
      
    },

    connectMetamask() {
      this.closePopup();
      let link =  document.location.href;
      link = link.substring(0, link.lastIndexOf('/'));
      link += "/metamask"
      window.location.href = link;
    },
    connectWalletConnect() {
      this.closePopup();
      let link =  document.location.href;
      link = link.substring(0, link.lastIndexOf('/'));
      link += "/walletconnect"
      window.location.href = link;
    },
    connectCoinbase() {
      this.closePopup();
      let link =  document.location.href;
      link = link.substring(0, link.lastIndexOf('/'));
      link += "/coinbase"
      window.location.href = link;
    },
    connectFortmatic() {
      this.closePopup();
      let link =  document.location.href;
      link = link.substring(0, link.lastIndexOf('/'));
      link += "/fortmatic"
      window.location.href = link;
    },
    connectBitski() {
      this.closePopup();
      let link =  document.location.href;
      link = link.substring(0, link.lastIndexOf('/'));
      link += "/bitski"
      window.location.href = link;
    },
    openPopup() {
      this.isShowPopup = true;
    },
    closePopup() {
      this.isShowPopup = false;
    }
	},
  beforeCreate() {
    this.$store.dispatch('showGnb',false);
  },
  async mounted() {
    this.myEventHandler(null);
    window.addEventListener("resize", this.myEventHandler);
    await this.loadApp();
  },
  destroyed() {
    this.$store.dispatch('showGnb',true);
  }
}
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;

  .backgroundImg-Blur {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    content:url('../assets/img/walletConnect/background_pc.png');
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }

  .container {
    position: absolute;
    width: 100vw;
    height: 100vh;

    .backgroundImg {
      position: absolute;
      z-index: 10;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      content:url('../assets/img/walletConnect/background_pc.png');
    }
    
    .walletConnectPage {
      position: relative;
      width: 100vw;
      height: 100vh;
      z-index: 11;

      font-family: Montserrat, sans-serif;
      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .wallet {
          display: block;
          margin-bottom: 92px;
          padding-top: 109px;
          width: 1500px;

          .address {
            display: inline;
            left: 675px;
            width: 350px;
            margin-top: 28px;
            font-size: gREm(23);
            vertical-align: middle;
            color: black;
          }

          .goApp {
            display: inline;
            left: 700px;
            width: 281px;
            height: 79px;
            margin-top: 31px;
            vertical-align: middle;
            @include SetBgImage(
              url('../assets/img/walletConnect/go_app_default.png')
            );

            &:hover {
              @include SetBgImage(
                  url('../assets/img/walletConnect/go_app_hover.png')
              );
				    }
          }

          .connect {
            display: block;
            left: 1050px;
            width: 281px;
            height: 79px;
            margin-top: 31px;
            @include SetBgImage(
              url('../assets/img/walletConnect/connect_default.png')
            );

            &:hover {
              @include SetBgImage(
                  url('../assets/img/walletConnect/connect_hover.png')
              );
				    }
          }
        }
      }
    }
  }
}

@include media-max($media_small) {
  .background {

    .backgroundImg-Blur {
      content:url('../assets/img/walletConnect/background_mobile.png');
    }

    .container {
      width: 100%;
      height: calc(100vw * 1920 / 1080);
      .backgroundImg {
        content:url('../assets/img/walletConnect/background_mobile.png');
      }
      
      .walletConnectPage {
        position: relative;
        width: 100vw;
        height: 100vh;
        z-index: 11;

        font-family: Montserrat, sans-serif;
        .info {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;
          .wallet {
          position: absolute;
            text-align: center;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 168px;
            vertical-align: top;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            .address {
              left: 0;
              display: block;
              width: 800px;
              font-size: gREm(37);
              color: black;
              margin: 400px auto 0;
            }

            .goApp {
              left: 0;
              display: block;
              width: 475px;
              height: 121px;
              margin: 86px auto 0;
              @include SetBgImage(
                url('../assets/img/walletConnect/go_app_default.png')
              );

              &:hover {
                @include SetBgImage(
                    url('../assets/img/walletConnect/go_app_hover.png')
                );
      		    }
            }

            .connect {
              left: 0;
              display: block;
              width: 475px;
              height: 121px;
              margin: 258px auto 0;
              @include SetBgImage(
                url('../assets/img/walletConnect/connect_default.png')
              );

              &:hover {
                @include SetBgImage(
                    url('../assets/img/walletConnect/connect_hover.png')
                );
      		    }
            }
          }
        }
      }
    }
}
}

.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-LOGIN-POPUP;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container {
			width: 480px;
			height: auto;
			margin: 0px auto;
			// padding: 20px 30px;
			background-color: #fff;
			border-radius: 20px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
			transition: all 0.3s ease;
			font-family: Helvetica, Arial, sans-serif;
		}
	}
}

.form {
  z-index: 11;
	@include FLEXV(flex-start, center);
	width: 100%;
	height: auto;
  padding: 50px 0 50px;

	.closebtn {
		@include SetBgImage(url('../assets/img/close-popup.svg'));
		width: gREm(18);
		height: gREm(18);
		cursor: pointer;
		@include OnOverTransition();
		position: absolute;
		top: gREm(27);
		right: gREm(29);
	}

	.connectbtn {
		padding-left: gREm(75);
		margin-top: gREm(10);
		width: gREm(324);
		height: gREm(64);
		border-radius: 6px;
		font-family: $AppFont;
		font-size: gREm(18);
		// @include Set-Font($AppFont, gREm(18), gREm(22), unset);
		@include OnOverTransition();
		& .ic-image {
			margin-right: gREm(15);
			max-width: 28px;
			max-height: 28px;
		}
		&:hover {
			transform: translateY(-2px);
		}
	}

}


@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container {
				width: 100%;
				max-width: 90vw;
				padding: 0 gREm(20);
				max-height: 95vh;
				overflow-y: auto;

				.form {
					
					.connectbtn {
						width: 100%;
						max-width: gREm(324);
						font-family: $AppFont;
						font-size: gREm(18);
					}
				}
			}
		}
	}
}
</style>
